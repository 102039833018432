import React from 'react';
import PropTypes from 'prop-types';

import useSpringEffect from 'use-spring-effect';

import useNonPassiveEvent from 'hooks/use-non-passive-event';

import Icon from 'components/icon';
import Image from '../image';
import Text from 'components/text';

const colorNames = {
  'Grey/Black': 'lens-visualisation-overlay-gray-black',
  Brown: 'lens-visualisation-overlay-brown',
  Green: 'lens-visualisation-overlay-green'
};

const LensVisualisation = ({ image, lensColor, text, isOverlayEnabled }) => {
  const isDragging = React.useRef(false);
  const sliderIsMoving = React.useRef(false);
  const xStart = React.useRef(false);

  // HTML elements:
  const [wrapper, setWrapper] = React.useState();
  const [overlay, setOverlay] = React.useState();
  const [sliderEl, setSliderEl] = React.useState();

  const [transitionTo] = useSpringEffect(
    0,
    value => {
      if (!overlay || !sliderEl) return;

      const transform = `translateX(${value}px)`;
      overlay.style.transform = transform;
      sliderEl.style.transform = transform;
    },
    { stiffness: 200, damping: 17 },
    [overlay, sliderEl]
  );

  const onMouseDown = () => (isDragging.current = true);
  const onMouseUp = () => (isDragging.current = false);

  const slider = event => {
    const x =
      event.pageX -
      wrapper.getBoundingClientRect().left -
      wrapper.offsetWidth / 2;

    transitionTo(x);
  };

  const onTouchStart = event => {
    xStart.current = event.touches[0].clientX;
  };

  const onTouchEnd = () => {
    sliderIsMoving.current = false;
  };

  const onTouchMove = event => {
    if (!xStart.current) return;

    const xMove = event.touches[0].clientX;

    const xDiff = xStart.current - xMove;

    if (Math.abs(xDiff) > 20 || sliderIsMoving.current) {
      event.preventDefault();
      slider(event.touches[0]);
      sliderIsMoving.current = true;
    }
  };

  const onMouseMove = event => {
    if (isDragging.current) slider(event);
  };

  useNonPassiveEvent('touchmove', wrapper, onTouchMove);

  return (
    <div className="lens-visualisation-wrapper">
      {isOverlayEnabled && colorNames[lensColor] ? (
        <div>
          <div
            className="lens-visualisation"
            ref={setWrapper}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onTouchCancel={onTouchEnd}
          >
            <Image src={image.src} alt={image.alt} draggable={false} />
            <div
              className={
                'lens-visualisation-overlay ' + colorNames[lensColor]
              }
              ref={setOverlay}
            />
            <div
              className="lens-visualisation-slider-wrapper"
              ref={setSliderEl}
            >
              <div className="lens-visualisation-slider">
                <span className="slider-line-top" />
                <div className="slider-arrow-group">
                  <div className="slider-knob">
                    <Icon
                      className="slider-arrow-left"
                      name="big-chevron-right"
                    />
                    <Icon
                      className="slider-arrow-right"
                      name="big-chevron-right"
                    />
                  </div>
                </div>
                <span className="slider-line-bottom" />
              </div>
            </div>
          </div>
          <Text.p theme={Text.themes.extraSmall}>{text}</Text.p>
        </div>
        ) : (
        <div className="lens-visualisation">
          <Image src={image.src} alt={image.alt} draggable={false} />
        </div>
      )}
    </div>
  );
};

LensVisualisation.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  lensColor: PropTypes.oneOf(Object.keys(colorNames)),
  text: PropTypes.string,
  isOverlayEnabled: PropTypes.bool
};

export default LensVisualisation;
