import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Text from 'components/text';
import Image from 'components/image';
import RichText from 'components/rich-text';
import Icon from 'components/icon';

const ProductsHeader = ({ title, text, image }) => {
  const hasImage = image && image.src;
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (contentRef.current) {
        const content = contentRef.current;
        setHeight(isOpen ? content.getBoundingClientRect().height : 0);
      }
    };

    updateHeight();

    if (isOpen) {
      window.addEventListener('resize', updateHeight);
    } else {
      window.removeEventListener('resize', updateHeight);
    }

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [isOpen]);

  const handleToggle = event => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };
  const ariaControl = 'products-header-content';
  const ariaLabelledby = 'products-header-title';

  return (
    <div
      className={cn('products-header', {
        'products-header--image': hasImage,
        'products-header--expanded': isOpen
      })}
    >
      <button
        className="products-header__toggle"
        onClick={handleToggle}
        aria-expanded={isOpen}
        aria-controls={ariaControl}
        id={ariaLabelledby}
      >
        <span>{title}</span>
        <Icon className="products-header__icon" name="chevron-down" />
      </button>

      <div
        id={ariaControl}
        className="products-header__expandable-content"
        style={{ maxHeight: `${height}px` }}
        role="region"
        aria-labelledby={ariaLabelledby}
      >
        <div className="products-header__content" ref={contentRef}>
          <div className="products-header__text">
            <div className="products-header__text-content">
              {text && (
                <RichText
                  text={text}
                  hasBottomMargin={false}
                  className="products-header__lead"
                />
              )}
            </div>
          </div>
          {hasImage && (
            <div className="products-header__image">
              <Image {...image} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ProductsHeader.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.exact(Image.propTypes)
};

ProductsHeader.propTypesMeta = {};

export default ProductsHeader;
